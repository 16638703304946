<template>
  <v-container>
    <v-row>
      <v-col class="mt-6 ml-4">
        <h1 class="display-1 font-weight-bold">ユーザ一覧</h1>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="data.users"
          :loading="loading"
          :custom-sort="customSort"
          @update:sort-by="updateSortBy"
          @update:sort-desc="updateSortDesc"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-form
                ref="searchForm"
                v-model="searchValid"
                lazy-validation
                @submit.prevent
              >
                <v-text-field
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  label="検索"
                  clearable
                  class="shrink"
                  :rules="searchRules"
                  @keyup.enter="doSearch"
                  @click:clear="clearSearch"
                ></v-text-field>
              </v-form>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                @click="openNew()"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                新規
              </v-btn>

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2 pa-0"
                    style="min-width: 36px;"
                  >
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(menu, i) in menus"
                    :key="i"
                    @click="clickMenu(menu)"
                  >
                    <v-list-item-title>{{ menu.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex align-center my-2">
              <v-avatar size="40">
                <img :src="item.picture"/>
              </v-avatar>
              <v-row class="ml-1">
                <v-col>
                  <div class="body-1">
                    <router-link
                      :to="{ name: 'User', params: { id: item.user_id } }"
                      style="text-decoration: none;"
                    >
                      {{ item.name }}
                    </router-link>
                  </div>
                  <div class="body-2">
                    {{ item.email }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:[`item.app_metadata.account_level`]="{ item }">
            <div v-if="item.app_metadata && item.app_metadata.account_level">
              {{ item.app_metadata.account_level | accountLevel }}
            </div>
          </template>
          <template v-slot:[`item.app_metadata.account_type`]="{ item }">
            <div v-if="item.app_metadata && item.app_metadata.account_type">
              {{ item.app_metadata.account_type | accountType }}
            </div>
          </template>
          <template v-slot:[`item.logins_count`]="{ item }">
            {{ item.logins_count ? item.logins_count : 0 }}
          </template>
          <template v-slot:[`item.last_login`]="{ item }">
            {{ item.last_login | fromNow }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          total-visible="7"
          :length="pageLength"
          @input = "setQueryParams"
        ></v-pagination>
      </v-col>
    </v-row>
    <create-user-dialog ref="new"></create-user-dialog>
    <bulk-export-users-dialog ref="bulkexport" :numusers="data.total"></bulk-export-users-dialog>
  </v-container>
</template>

<script>
import axios from "axios"
import moment from "moment"
import CreateUserDialog from '@/components/CreateUserDialog'
import BulkExportUsersDialog from '@/components/BulkExportUsersDialog'

import {ACCOUNT_LEVEL_LIST, ACCOUNT_TYPE_LIST} from '@/mixin/metadata.js'

export default {
  components: {
    CreateUserDialog,
    BulkExportUsersDialog,
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: '会員種別', value: 'app_metadata.account_level', sortable: false  },
        { text: '所属機関', value: 'app_metadata.account_type', sortable: false  },
        { text: 'Logins', value: 'logins_count' },
        { text: 'Latest Login', value: 'last_login' },
        { text: '', value: 'actions', sortable: false },
      ],
      loading: false,

      search: '',
      searchValid: false,
      searchRules: [
        v => (v && v.trim().length >= 3) || '3文字以上入力してください'
      ],

      page: 1,
      data: {
        users: [],
        total: 0,
        limit: 10,
      },
      sortBy: null,
      sortDesc: null,

      menus: [
        { title: 'xlsxインポート', id: 'bulkimportxlsx'},
        { title: 'csvインポート', id: 'bulkimport'},
        { title: 'csvダウンロード', id: 'bulkexport'},
      ]
    }
  },
  computed: {
    pageLength: function() {
      return Math.ceil(this.data.total / this.data.limit)
    }
  },
  filters: {
    fromNow(value) {
      return value ? moment(value).fromNow() : 'never'
    },
    accountLevel(value) {
      let item = ACCOUNT_LEVEL_LIST.find((v) => v.value === value)
      return item ? item.text : ''
    },
    accountType(value) {
      let item = ACCOUNT_TYPE_LIST.find((v) => v.value === value)
      return item ? item.text : ''
    },
  },
  watch: {
    '$route': function () {
      this.setSearchParams()
      this.fetchUsers()
    },
  },
  created() {
    this.setSearchParams()
    this.fetchUsers()
  },
  methods: {
    async fetchUsers() {
      this.loading = true
      const accessToken = await this.$auth.getTokenSilently()
      const params = await this.makeParams()
      await axios
        .get("/api/users", {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          params: params,
        })
        .then(response => {
          this.data = response.data
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editItem(item) {
      this.$router.push({name: 'User', params: { id: item.user_id }})
    },
    openNew() {
      this.$refs.new.open()
    },
    makeParams() {
      let params = {
        page: this.page -1
      }
      if (this.search) {
        params.q =  `name:*${this.search}* OR email:*${this.search}*`
      }
      if (this.sortBy) {
        params.sort =  this.sortDesc ? `${this.sortBy}:-1` : `${this.sortBy}:1`
      }
      return params
    },
    doSearch() {
      if (this.$refs.searchForm.validate()) {
        this.page = 1
        this.setQueryParams()
      }
    },
    clearSearch() {
      this.search = ''
      this.$refs.searchForm.reset()
      this.setQueryParams()
    },
    updateSortBy(v) {
      this.sortBy = v.length ? v[0] :  null
    },
    // ソート変化時にフェッチ
    updateSortDesc(v) {
      this.sortDesc = v.length ? v[0] :  null
      this.setQueryParams()
    },
    // Auth0 Management APIでソートするため、デフォルトのソート機能は無効化
    customSort() {
      return this.data.users
    },
    makeQuery() {
      let query = {
        page: this.page -1
      }
      if (this.search) {
        query.q =  this.search.trim()
      }
      if (this.sortBy) {
        query.sort =  this.sortDesc ? `${this.sortBy}:-1` : `${this.sortBy}:1`
      }
      return query
    },
    setQueryParams() {
      this.$router.push({ name: "Users", query: this.makeQuery() })
    },
    setSearchParams() {
      this.page = this.$route.query.page ? Number(this.$route.query.page) + 1 : 1
      this.search = this.$route.query.q ? this.$route.query.q : ''
      if (this.$route.query.sort) {
        let s = this.$route.query.sort.split(':')
        this.sortBy = s[0]
        this.sortDesc = s[1] === '-1' ? true : false
      } else {
        this.sortBy = null
        this.sortDesc = null
      }
    },
    clickMenu(menu) {
      if (menu.id==='bulkexport') {
        this.$refs.bulkexport.open()
      } else if (menu.id==='bulkimport') {
        this.$router.push({name: 'BulkImportUsers'})
      } else if (menu.id==='bulkimportxlsx') {
        this.$router.push({name: 'BulkImportUsersXlsx'})
      }
    }
  }
}
</script>