<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">新規ユーザ作成</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col col="6">
              <div class="subtitle-1 font-weight-bold mb-1">
                メールアドレス（ID）
              </div>
              <v-text-field
                v-model="form.email"
                outlined
                dense
                placeholder="email@example.com"
                :rules="rules.email"
              ></v-text-field>
              <div class="subtitle-1 font-weight-bold mb-1">表示名</div>
              <v-text-field
                v-model="form.name"
                outlined
                dense
                placeholder="東京-日本太郎-jc3"
                :rules="rules.text"
              ></v-text-field>
              <div class="subtitle-1 font-weight-bold mb-1">パスワード</div>
              <v-text-field
                v-model="form.password"
                outlined
                dense
                type="password"
                placeholder="********"
                :rules="rules.text"
              ></v-text-field>
              <div class="subtitle-1 font-weight-bold mb-1">
                パスワード（確認用）
              </div>
              <v-text-field
                v-model="confirmPassword"
                outlined
                dense
                type="password"
                placeholder="********"
                :rules="rules.confirmPassword"
              ></v-text-field>
              <div class="subtitle-1 font-weight-bold mb-1">会員種別</div>
              <v-select
                v-model="form.app_metadata.account_level"
                :items="accountLevelList"
                placeholder="選択してください"
                dense
                solo
                :rules="rules.accountLevel"
              ></v-select>
              <div class="subtitle-1 font-weight-bold mb-1">所属種別</div>
              <v-select
                v-model="form.app_metadata.account_type"
                :items="accountTypeList"
                placeholder="選択してください"
                dense
                solo
                :rules="rules.accountType"
              ></v-select>
            </v-col>
            <v-col col="6">
              <div class="subtitle-1 font-weight-bold mb-1">氏名（漢字）</div>
              <v-text-field
                v-model="form.app_metadata.fullname"
                outlined
                dense
                placeholder="日本 太郎"
                :rules="rules.text"
              ></v-text-field>
              <div class="subtitle-1 font-weight-bold mb-1">フリガナ</div>
              <v-text-field
                v-model="form.app_metadata.furigana"
                outlined
                dense
                placeholder="ニッポン タロウ"
                :rules="rules.text"
              ></v-text-field>
              <div class="subtitle-1 font-weight-bold mb-1">
                アルファベット表示
              </div>
              <v-text-field
                v-model="form.app_metadata.alphabet_name"
                outlined
                dense
                placeholder="NIPPON TARO"
                :rules="rules.text"
              ></v-text-field>
              <!-- 正・特定会員のみ -->
              <div v-if="form.app_metadata.account_type === 'C'">
                <div class="subtitle-1 font-weight-bold mb-1">
                  情報管理規定等適用者確認
                </div>
                <v-switch
                  v-model="form.app_metadata.applicant_verified"
                  :label="
                    form.app_metadata.applicant_verified ? '確認済み' : '未確認'
                  "
                ></v-switch>
              </div>
              <!-- 警察のみ -->
              <div
                v-if="
                  ['P1', 'P2', 'P3', 'P4'].includes(
                    form.app_metadata.account_type
                  )
                "
              >
                <div class="subtitle-1 font-weight-bold mb-1">所属</div>
                <v-text-field
                  v-model="form.app_metadata.belongs"
                  outlined
                  dense
                  placeholder="所属"
                  :rules="rules.text"
                ></v-text-field>
              </div>
              <!-- 所属用のメールアドレス登録時のみ -->
              <div
                v-if="
                  ['P1', 'P2', 'P3', 'P4'].includes(
                    form.app_metadata.account_type
                  ) && isBelongsEmail()
                "
              >
                <div class="subtitle-1 font-weight-bold mb-1">
                  連絡先電話番号
                </div>
                <v-text-field
                  v-model="form.app_metadata.telephone_number"
                  outlined
                  dense
                  placeholder="xxx-xxxx-xxxx"
                  :rules="rules.text"
                ></v-text-field>
                <div class="subtitle-1 font-weight-bold mb-1">上司氏名</div>
                <v-text-field
                  v-model="form.app_metadata.boss_name"
                  outlined
                  dense
                  placeholder="上司 太郎"
                  :rules="rules.text"
                ></v-text-field>
                <div class="subtitle-1 font-weight-bold mb-1">上司連絡先</div>
                <v-text-field
                  v-model="form.app_metadata.boss_email"
                  outlined
                  dense
                  placeholder="email@example.com"
                  :rules="rules.email"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <v-alert v-if="error" type="error">{{ error }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">キャンセル</v-btn>
        <v-btn color="primary" @click="send" :loading="loading">作成</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { ACCOUNT_LEVEL_LIST, ACCOUNT_TYPE_LIST } from "@/mixin/metadata.js";

export default {
  data() {
    return {
      error: "",
      dialog: false,
      form: {
        email: "",
        name: "",
        password: "",
        app_metadata: {
          account_level: "",
          account_type: "",
        },
      },
      rules: {
        email: [
          (v) => !!v || "入力してください。",
          (v) =>
            /.+@.+\..+/.test(v) || "メールアドレス形式で入力してください。",
        ],
        text: [(v) => !!v || "入力してください。"],
        confirmPassword: [
          (v) => !!v || "入力してください。",
          (v) => v === this.form.password || "パスワードが一致しません。",
        ],
        accountLevel: [(v) => !!v || "選択してください。"],
        accountType: [(v) => !!v || "選択してください。"],
      },
      confirmPassword: "",
      valid: true,
      loading: false,

      accountLevelList: ACCOUNT_LEVEL_LIST,
      accountTypeList: ACCOUNT_TYPE_LIST,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    send() {
      this.error = "";
      if (this.$refs.form.validate()) {
        this.createUser();
      }
    },
    async createUser() {
      this.loading = true;
      const accessToken = await this.$auth.getTokenSilently();
      await axios
        .post("/api/users", this.form, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          if (response.data.statusCode === 409) {
            this.error = "このメールアドレスは既にに登録されています。";
          } else {
            this.dialog = false;
            this.$emit("success", "ユーザの作成に成功しました。");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$emit("error", "ユーザの作成に失敗しました。");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isBelongsEmail() {
      return this.form.email && this.form.email.endsWith("@jc3-learning.org")
        ? true
        : false;
    },
  },
};
</script>
