<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline">一括ダウンロード</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        登録済みのユーザ {{ numusers }}件 をCSVファイルでエクスポートします。
        <v-alert v-if="error" type="error" class="mt-1" dense>{{ error }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog=false">キャンセル</v-btn>
        <v-btn color="primary" @click="send" :loading="loading">CSVダウンロード</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  props: ['numusers'],
  data() {
    return {
      error: '',
      dialog: false,
      loading: false,
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    send() {
      this.error = ''
      this.createUser()
    },
    getFileNameFromContentDisposition(disposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/; // 正規表現でfilenameを抜き出す
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        const fileName = matches[1].replace(/['"]/g, '');
        return decodeURI(fileName) // 日本語対応
      } else {
        return null
      }
    },
    async createUser() {
      this.loading = true
      const accessToken = await this.$auth.getTokenSilently()
      await axios.get("/api/users-exports", {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        responseType: 'blob'
      })
      .then((response) => {
        const url = URL.createObjectURL( new Blob([response.data], { type: "application/zip" }) );
        const filename = this.getFileNameFromContentDisposition(response.headers['content-disposition'])

        const link = document.createElement("a");
        link.href = url;
        link.download = filename
        link.click();
        URL.revokeObjectURL(url);
        this.dialog = false
      })
      .catch(error => {
        this.error = 'エラーが発生しました。'
        console.log(error);
      })
      .finally(() => {
        this.loading = false
      })
    },
  }
}
</script>